<template>
    <FormWrapper :isLoading="isLoading">
        <PageTitle :title="name" slot="title" />
        <div v-html="text"></div>
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import { getHelpContentByPageURL } from './api'
    import loadingMixin from 'Mixins/loadingMixin'
    export default {
        name: "HelpPage",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle
        },
        data() {
            return {
                name: '',
                text: ''
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                setTimeout(() => {
                    this.showLoader();
                    const data={
                        PageURL: this.$route.params.name
                    };
                    getHelpContentByPageURL(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            handleResponse(response) {
                this.hideLoader();
                this.name = (response.data[0])?'Help- ' + response.data[0].PageName:'Help';
                this.text = (response.data[0])?response.data[0].Content:'Help Content Not Available';
            },
        }
    }
</script>
<style lang="scss" module>
    header {
        display: none !important;
    }

    //Changes by Mahima on 14th July 2023: Added display-none property so that left aside bar won't be displayed.
    aside {
        display: none !important;
    }
</style>